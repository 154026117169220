@import './theme';
$opacity: 0.1;
$largeSize: 100px;
$mediumSize: 80px;
$smallSize: 60px;
$extraSmallSize: 40px;

/* Main Rules */
body {
  background: #eee;
}

/* loadingScreen  */
.loadingScreen {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* loadingScreen Circles */

  &__circle {
    width: $largeSize;
    height: $largeSize;
    border: 4px solid rgba(map-get($theme-colors, "primary"), $opacity);
    border-top-color: map-get($theme-colors, "primary");
    border-bottom-color: map-get($theme-colors, "primary");
    border-radius: 50%;
    position: relative;
    animation: Loading1 2s linear 0s infinite;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &__reverse,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 0px;
      right: 0px;
      margin: auto;
    }

    &:after {
      width: $mediumSize;
      height: $mediumSize;
      border: 4px solid rgba(map-get($theme-colors, "secondary"), $opacity);
      border-left-color: map-get($theme-colors, "secondary");
      border-right-color: map-get($theme-colors, "secondary");
      animation: Loading1 2s linear 0s infinite;
    }

    &:before {
      width: $extraSmallSize;
      height: $extraSmallSize;
      border: 4px solid rgba(map-get($theme-colors, "success"), $opacity);
      border-top-color: map-get($theme-colors, "success");
      border-bottom-color: map-get($theme-colors, "success");
      animation: Loading1 2s linear 2s infinite;
      display: block;
    }

    &__reverse {
      width: $smallSize;
      height: $smallSize;
      border: 4px solid rgba(map-get($theme-colors, "dark"), $opacity);
      border-left-color: map-get($theme-colors, "dark");
      border-right-color: map-get($theme-colors, "dark");
    }
  }
}

/* Animation */

@keyframes Loading1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Standard syntax */

@keyframes Loading2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
