@import './theme';
@import './loader';

html {
  position: relative;
  min-height: 100%;
}

th {
  cursor: pointer;
}

.messages {
  color: #fff;
}

#main-nav.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.btn:hover {
  cursor: pointer;
}

th {
  cursor: pointer;
}

.page-item {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

input[type=checkbox] {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.metadata {
  font-size: .85em;
  color: gray;
}

hr {
  margin-top: 5px;
}

.metatitle {
  font-size: .9em;
  display: inline-block;
  border-bottom: 1px solid #e6e4e4;
  margin-bottom: 5px;
}

.wide-modal {
  max-width: 1250px;
}

html {
  font-size: .9rem;
}

.no-resize {
  resize: none;
}

.dropdown-submenu {
  position: relative;
  padding-left: 0.5rem;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > .dropdown-submenu-text:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > .dropdown-submenu-text:after {
  border-left-color: #000;  
}

.dropdown-submenu-text{
  cursor: pointer;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.dropdown-item {
    cursor: pointer;
}
